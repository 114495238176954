import React, { useState } from "react";
import TopButton from "../../components/topButton/TopButton";
import "./ContactComponent.css";

const Contact = (props) => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleContactMe = () => {
    const requestBody = JSON.stringify({
      "email": email,
      "message": ""
    })
  
    const requestOptions = {
      method: 'POST',
      headers: { 
        "Content-Type": "application/json",
      },
      body: requestBody
    }

    fetch('https://api.shippy.app/api/email', requestOptions)
      .then(response => response.json())
      .catch(error => {
        console.log('error', error)
      });
  }
  
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center',width: '90%', margin: '0 auto', paddingTop: '6rem', paddingBottom: '6rem'}}>
        <div style={{ display: 'flex', alignItems: 'center',  width: '100%', margin: '0 auto', flexDirection: 'column'}}>
          <span style={{ fontSize: '3rem', textAlign: 'center', color: '#000'}}>Let's simplify your shipping together</span>
          <span style={{ fontSize: '1.3rem', color: '#000', marginTop: '1.5rem'}}/>
          <div style={{ display: 'flex', justifyContent: 'center', width: '40%', marginTop: '2rem'}}>
            <input style={{ flex: 0.8, fontSize: '1.4rem', paddingTop: '1rem', paddingBottom: '1rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }} type="text" placeholder="Your email address" onChange={handleEmailChange}></input>
            <div style={{ display: 'flex', flex: 0.2, cursor: 'pointer', padding: '1rem', backgroundColor: '#6738EE', color: '#FFF', alignItems: 'center', justifyContent: 'center', marginLeft: '1rem', textAlign: 'center' }} onClick={handleContactMe}>
              <span style={{ fontSize: '1.3rem'}}>Contact me</span>
            </div>
          </div>
        </div>
      </div>
      <TopButton theme={props.theme} />
    </>
  );
}

export default Contact;
