import React, { Component } from "react";
import Header from "../../components/header/Header";
import Hero from "../../containers/hero/Hero.js";
import Features from "../../containers/features/Features.js";
import TopButton from "../../components/topButton/TopButton";
import Contact from "../contact/ContactComponent";

class Home extends Component {
  conso;
  render() {
    return (
      <div style={{ backgroundColor: '#FFF'}}>
        <Header
          theme={this.props.theme}
          seoTitle={"Shippy"}
          seoDescription={
            "Jonas Lundholm is an experienced Freelance Software Developer focusing on building websites using modern technologies and great web design. With over 5 years in the industry I've worked with multiple successful startups."
          }
        />
        {/* {hasUserConsentededToCookies() === undefined && <CookieNotice/>} */}
        <Hero theme={this.props.theme} />
        <Features theme={this.props.theme} />
        <Contact theme={this.props.theme} />
        {/* <Greeting theme={this.props.theme} /> */}
        {/* <Hello
          theme={this.props.theme}
          backgroundColor={this.props.theme.secondaryBackgroundColor}
          titleTextColor={"black"}
          textColor={"#555555"}
          linkTextColor={"#555555"}
        />
        <WorkTogether theme={this.props.theme}></WorkTogether> */}
        {/* <Footer theme={this.props.theme} /> */}
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Home;
