/* Change this file to get your personal Porfolio */

const app = {
  logoLink: "./256.png"
}

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Jonas Lundholm",
  description:
    "Website and portfolio for Jonas Lundholm, Software Developer on ther Internet",
  og: {
    title: "Jonas Lundholm",
    type: "website",
    url: "https://jlundholm.com",
  },
};

//Home Page
const greeting = {
  logo_name: "</>",
  resumeLink:
    "https://drive.google.com/file/d/1FL_wydnPF2SWCdw2goYAgZ1KKBx8TpcD/view?usp=sharing",
  portfolio_repository: "https://github.com/jlundholm-git",
  githubProfile: "https://github.com/jlundholm-git",
};

const aboutMe = {
  title: {
    se: "Hej!",
    en: "Hi!",
  },
  descriptionHtml: {
    se: `
      <p>Jonas Lundholm heter jag och är en webbutvecklare från Sverige 🇸🇪</p>
      <p>I mer än 5 års tid har jag jobbat för några av Sveriges största startups som <a class="aboutme-description-link" href="https://budbee.com/">Budbee</a> och 
      <a class="aboutme-description-link" href="https://kry.se/en">Kry</a>, men fokuserar nu på att bygga hemsidor och webbapplikationer som egenföretagare.</p>
      <p>Ledstjärnan i mitt hanterk är användarupplevelse, prestanda och snygg design. Jag siktar alltid på att använda rätt verktyg för rätt jobb 
      och att man alltid får tillbaks den tid och de pengar man lägger på ett gott förarbete.</p>
      <p>Utanför jobbet snickrar jag gärna, klappar katter eller går ut i skogen</p>`,
    en: `
      <p>My name is Jonas and I'm a Software Engineer from Sweden 🇸🇪</p>
      <p>For more than 5 years I've worked with some of Sweden's fastest rising startups like <a class="aboutme-description-link" href="https://budbee.com/">Budbee</a> and 
        <a class="aboutme-description-link" href="https://kry.se/en">Kry</a>, but I'm now focusing full time on building websites and web applications as a freelancer.</p>
      <p>At the center of my craft is user experience, performance and good design. I alwys aim for finding the right tool for the right job and 
        sincerely believe that time and money spent in preparation for a project is saved later on.</p>
      <p>When I'm not working I enjoy carpentry, petting my cats and spending time in the forest</p>
    `,
  },
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/jlundholm-git",
    fontAwesomeIcon: "fa-github",
    backgroundColor: "#181717",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/jonaslundholm/",
    fontAwesomeIcon: "fa-linkedin-in",
    backgroundColor: "#0077B5",
  },
  // {
  //   name: "YouTube",
  //   link: "",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:jonas@jlundholm.com",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  // {
  //   name: "Twitter",
  //   link: "",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  // {
  //   name: "Facebook",
  //   link: "",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const copy = {
  get: (key) => {
    const language = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en";
    const se = {
      headerProjectLinkText: "Projekt",
      headerProjectsLinkUrl: "/projekt",
      headerAboutMeLinkText: "Om mig",
      headerAboutMeLinkUrl: "/om-mig",
      headerEcommerceLinkText: "E-Handelslösningar",
      headerEcommerceLinkUrl: "/e-handel",
      homePageTitle: "Hej!",
      homePageSubtitle:
        "Jag heter Jonas. Jag hjälper dig bygga snygga hemsidor på nätet.",
      callToActionTitle: "Vill du ha hjälp med ditt projekt? Hör av dig!",
      callToActionEcommerceTitle: "Vill du boka en demonstration?",
      callToActionSubtitle:
        "Jag är tillgänglig för projekt på distans och tar gärna ett möte över telefon eller videochatt.",
      callToActionButtonTitle: "Skicka ett mejl",
      helloSectionTitle: "Mina senaste projekt",
      helloSectionSubtitle: "Här är några av mina senaste projekt",
      productSectionTitle: "Jag hjälper dig komma igång med din e-handel",
      footerNameTitle: "Namn",
      footerEmailTitle: "Email",
      footerCookieTitle: "Integritetspolicy",
      footerCookieUrl: "/cookies",
      cookieNotice:
        "Den här sidan sparar endast en cookie lokalt för att komma ihåg ditt valda språk. Cookien tas automatiskt bort efter 24 timmar.",
    };
    const en = {
      headerProjectLinkText: "Projects",
      headerProjectsLinkUrl: "/projects",
      headerAboutMeLinkText: "About me",
      headerAboutMeLinkUrl: "/about-me",

      signInLinkText: "Sign in",
      signInLinkUrl: "/signin",
      signUpLinkText: "Sign up",
      signUpLinkUrl: "/signup",
      pricingLinkText: "Pricing",
      pricingLinkUrl: "/pricing",
      privacyPolicyLinkText: "Privacy policy",
      privacyPolicyLinkUrl: "/privacy-policy",
      homePageTitle: "Welcome to Tidy!",
      homePageSubtitle:
        "I'm Jonas. I help people build great experiences on the internet.",
      callToActionTitle: "Got a project in mind? Let's work together!",
      callToActionSubtitle:
        "I'm available for fully remote projects and happy to chat or meet over your favourite video meeting app.",
      callToActionButtonTitle: "Get in touch",
      helloSectionTitle: "My recent projects",
      productSectionTitle: "",
      helloSectionSubtitle:
        "Here are some of the projects I've recently worked on.",
      footerNameTitle: "Name",
      footerEmailTitle: "Email",
      footerCookieTitle: "Privacy policy",
      footerCookieUrl: "/cookies",
      cookieNotice:
        "This website saves one cookie to remember your chosen language. The cookie is automatically removed after 24 hours.",
      privacyPolicyNotice:
        `
        Preview your GDPR Privacy Notice
        Privacy Policy for LundTech
        At tidy-app.ai, accessible from https://tidy-app.ai, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by tidy-app.ai and how we use it.
        
        If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us. Our Privacy Policy was generated with the help of GDPR Privacy Policy Generator
        
        General Data Protection Regulation (GDPR)
        We are a Data Controller of your information.
        
        LundTech legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:
        
        LundTech needs to perform a contract with you
        You have given LundTech permission to do so
        Processing your personal information is in LundTech legitimate interests
        LundTech needs to comply with the law
        LundTech will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.
        
        If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.
        
        In certain circumstances, you have the following data protection rights:
        
        The right to access, update or to delete the information we have on you.
        The right of rectification.
        The right to object.
        The right of restriction.
        The right to data portability
        The right to withdraw consent
        Log Files
        tidy-app.ai follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
        
        Privacy Policies
        You may consult this list to find the Privacy Policy for each of the advertising partners of tidy-app.ai.
        
        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on tidy-app.ai, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
        
        Note that tidy-app.ai has no access to or control over these cookies that are used by third-party advertisers.
        
        Third Party Privacy Policies
        tidy-app.ai's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
        
        You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
        
        Children's Information
        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
        
        tidy-app.ai does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
        
        Online Privacy Policy Only
        Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in tidy-app.ai. This policy is not applicable to any information collected offline or via channels other than this website.
        
        Consent
        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
        `,
    };
    return language === "se" ? se[key] : en[key];
  },
};

const offerings = {
  data: [
    {
      id: 0,
      title: "Recurring TODOs - Powered by AI",
      descriptionHtml: {
        // se: `
        //   <p>Produkthantering sköter du själv genom Shopifys användargränssnitt. Det är byggt och anpassat för att du som butiksägare snabbt ska få en överblick över 
        //   dina produkter, deras lagersaldo och olika produktvarianter som färger och storlekar. Här kan du enkelt:</p>
        //   <ul>
        //     <li>Ladda upp produktbilder</li>
        //     <li>Skriva produktbeskrivningar</li>
        //     <li>Skapa produktvarianter med egna bilder, olika färger och storlekar</li>
        //     <li>Se produktens lagersaldo</li>
        //   </ul>
        // `,
        en: `
          <section>
          <p class="what-is-tidy-subtitle">What is Tidy?</p>
          <div class="what-is-tidy-list-items">
            <span>Tidy is an app that helps you remember and prioritize the small things in life that we sometimes forget</span>
          </div>

          <p class="what-is-tidy-subtitle">How does it work?
            <div class="what-is-tidy-list-items">
              <span>The built in scheduling feature lets you set up the tasks you'd like to remember and if these should be done every day, a few times a week or once a month.</span>
              <br/>
              <br/>
              <span>If you're overwhelmed and don't know where to start, just press a button to get some recommendations and friendly encouragement from a robot 🤖</span>
            </div>
          </p>

          <p class="what-is-tidy-subtitle">This sounds a lot like a calendar. Why did you build a calendar?
            <div class="what-is-tidy-list-items">
              <span>Confession time, calendars have never worked for me.</span>
              <br/>
              <br/>
              <span>I forget to check them, forget to write in them and having to use one calendar app to remember and another for the TODOs themselves is too much work.</span>
            </div>
          </p>
          
          </section>
        `,
      },
      imageLink: "./photo_2023-11-08_12-47-19.jpg",
      imageAlign: "left",
    },
    {
      id: 1,
      title: "Where can I get Tidy?",
      descriptionHtml: {
        // se: `
        //   <p>Produktserier ger dig full kontroll över vilka produkter som hör till vilka kategorier och underkategorier.</p>
        //   <p>När produkter ska läggas ut på rea skapar du en ny produktserie, lägger till vilka produkter som ska reas ut i produktserien och laddar upp en bild för bannern. </p>
        //   <p>Du kan automatiskt lägga till vilka produkter som ska hamna i rean genom att tagga produkten, sedan är det bara att säkerställa att rätt produkter lagts till och börja sälja. </p>
        // `,
        en: `
          <section>
            <div class="what-is-tidy-list-items">
              <span>Tidy is available for both Android and iOS</span>
              <br/>
              <br/>
              <span>If you enjoy using the app, make my day by leaving a ⭐⭐⭐⭐⭐ review.</span>
              <br/>
              <br/>
              <span>To download Tidy for iOS, visit the App Store</span>
              <br/>
              <span>To download Tidy for Android, visit the Google Play Store</span>
            </div>
            <p class="what-is-tidy-subtitle">How much is Tidy?</p>
              <div class="what-is-tidy-list-items">
                <span>$9.95. It's a one-time fee and after that it's yours forever.</span>
              </div>
            <p class="what-is-tidy-subtitle">Why does Tidy cost money?</p>
            <div class="what-is-tidy-list-items">
              <span>Behind this app is <a class="twitter-link" href="https://twitter.com/lundapps">one guy</a> that hopes to one day make a living building apps that makes your life just a bit easier.</span>
              <br/>
              <br/>
              <span>
                There are no subscriptions, no ads or in-app purchases and I'll never sell your information to third parties.
              </span>
            </div>

            </section>     
        `,
      },
      imageLink: "./photo_2023-11-08_12-47-19.jpg",
      imageAlign: "right",
    },
  ],
}

const aboutUs = {
  data: [
    {
      id: 0,
      title: "Ehandel - Garn & Tygladan i Vinnersjö",
      url: "https://vinnersjogarn.se",
      descriptionHtml: {
        se: `
          <p>Komplett e-handel för Garn & Tygladan i Vinnersjö - Byggd ovanpå Shopifys API och CMS.</p>
          <p>Kunden använder Shopifys eget CMS för att lägga till produkter, byta bilder, lägga produkter på REA och ändra vilka produkter som ska synas på framsidan - Helt utan att sidan behöver startas om. 
          
          <p>Den tekniska lösningen är byggd för att kunden ska kunna driva sin e-handel så enkelt som möjligt utan att känna att de behöver fråga mig som utvecklare efter hjälp för minsta lilla ändring.</p>
          
          <p>Infrastrukturen är helautomatiserad och kan blixtsnabbt sättas upp för nya kunder.</p>
          <div class="product-ecommerce-cta-container">
            <a class="product-ecommerce-cta" href="/e-handel">Vill du också komma igång med din e-handel? 
            <br/>Läs mer här!</a>
          </div>
          
        `,
        en: `
        <p>The customer uses Shopify's own CMS to add products, change images, put products on sale, and change which products should be displayed on the front page - All without requiring any site downtime or restarts.</p>
        <p>The infrastructure is fully automated, allowing for rapid setup for new customers, speeding up delivery and efficiency.</p>
        <div class="product-ecommerce-cta-container">
          <a class="product-ecommerce-cta" href="/e-handel">Would you like some help getting with e-commerce? 
          <br/>Learn more!</a>
        </div>
        `,
      },
      imageLink: "./garn_tygladan_vinnersjo_portfolio.png",
      imageAlign: "left",
    },
    {
      id: 1,
      title: "Nordiska Rör & VVS",
      url: "https://nordiska-vvs.jlundholm.com",
      descriptionHtml: {
        se: `
          <p>Demosida för en VVS-firma i Gästrikland</p>
          <p>Hemsidan är responsiv och fungerar både på mobil, PC och iPad. Blixtsnabb laddning och toppbetyg i prestandatester.</p>
          <p>Utforskar utmanande koncept som text över fullskärmsbilder, olika layouts och en animerad meny på mobil som
          trycker undan utrymme för att göra plats för sig själv.</p>
          <p>Bild tagen utanför Storsjön, söder om Sandviken</p>
        `,
        en: `
          <p>Demo site for a plumbing business in Gästrikland, Sweden</p>
          <p>The website is fully responsive, lightning fast and top-rated in performance tests</p>
          <p>It features text over full-width images, multiple sections and an animated header on mobile that 
          pushes content out of the way to make room for itself.</p>
          <p>Picture taken outside of Storsjön, south of Sandviken</p>
        `,
      },
      imageLink: "./nordiska_vvs_mobile.jpg",
      imageAlign: "right",
    },

    {
      id: 2,
      title: "East West Gallery",
      url: "https://art-gallery-demo.jlundholm.com/",
      descriptionHtml: {
        se: `
          <p>Demosida för öppnandet av en påhittad konstutställning.</p>
          <p>Responsiv hemsida som fungerar utmärkt både på mobil, PC och iPad. Blixtsnabb laddning och toppbetyg i prestandatester.</p>
          <p>Sidan innehåller även ett formulär för att OSA till evenemanget.</p>
        `,
        en: `
          <p>Website for the opening of a fictional art gallery, fully responsive on mobile & desktop. Website comes complete with a signup form to RSVP to the event</p>
        `,
      },
      imageLink: "./east-west-gallery.webp",
      imageAlign: "left",
    },
    // {
    //   id: 1,
    //   title: "En firma du kan lita på",
    //   imageLink: "./gravmaskin.jpg",
    //   descriptionHtml: `
    //     <p>Som kund hos oss ska du känna dig trygg från projektstart till slutförande. Efter genomfört arbete med oss lämnar vi 3 års garanti på installationen, och vid frågor är det bara att ringa.</p>
    //     <p>När du beställer enskilt avlopp & infiltration från oss sköter vi all kontakt med kommun, miljökontor samt skickar in nödvändiga blanketter. Vi tar även kontakt med Ledningskollen för att undvika att gräva genom andras ledningar. </p>
    //     <p>Efter genomfört arbete får du certifikat efter godkänd installation och inspektion.</p>
    //     `,
    //   imageAlign: "right",
    // },
    // {
    //   id: 2,
    //   title: "Vi installerar din värmepump",
    //   imageLink: "./infiltrationsanlaggning.webp",
    //   descriptionHtml: `
    //     <p>Vi installerar Luft/luft-värmepumpar, Luft/vatten-värmepumpar och genomför projektering inför bergvärme.</p>
    //     <p>Installation av luft & vattenvärmepumpar kan oftast genomföras på 1-2 dagar och vid bergvärme hjälper vi dig hela vägen.</p>
    //     <p>Vid installation av bergvärme genomförs först en kartläggning av befintliga förhållanden som geologi, markförhållanden och byggnadens värmebehov. Här görs en ekonomisk utvärdering för att bedöma lönsamheten för en ny bergvärmeanläggning där miljöaspekten tas i beaktning.</p>
    //     <p>Därefter genomförs en detaljerad analys av byggnadens värme- och kylbehov, och baserat på det dimensioneras installationsmateriel och borrhålet.
    //     <p>Efter planeringsfasen beställer vi bergvärmepumpen, tar upp hålet och installerar pumpen. Slutligen idrifttas pumpen och anläggningen testkörs. Därefter återkommer vi efter ett par veckor för en uppföljning och för att se till att alting fungerar som det ska.</p>

    //     `,
    //   imageAlign: "left",
    // },
  ],
};

const work = {
  data: [
    {
      id: 0,
      title: "Nordiska Rör & VVS",
      url: "https://nordiska-vvs.jlundholm.com",
      imageLink: "./house_building.jpg",
      descriptionHtml: `
        <p>Demo site for a plumbing business in Gästrikland, Sweden</p>
        <p>The website is fully responsive, lightning fast and scores for performance (99%) on GTMetrix</p>
        <p>It leverages multiple challenging CSS components such as text over full-width images and an animated header on mobile that 
        pushes content out of the way to make room for itself</p>
      `,
      imageAlign: "left",
    },
    {
      id: 1,
      title: "En firma du kan lita på",
      imageLink: "./gravmaskin.jpg",
      descriptionHtml: `
        <p>Som kund hos oss ska du känna dig trygg från projektstart till slutförande. Efter genomfört arbete med oss lämnar vi 3 års garanti på installationen, och vid frågor är det bara att ringa.</p>
        <p>När du beställer enskilt avlopp & infiltration från oss sköter vi all kontakt med kommun, miljökontor samt skickar in nödvändiga blanketter. Vi tar även kontakt med Ledningskollen för att undvika att gräva genom andras ledningar. </p>
        <p>Efter genomfört arbete får du certifikat efter godkänd installation och inspektion.</p>
        `,
      imageAlign: "right",
    },
  ],
};
// }

// const work = {
//   data: [
//     {
//       id: 0,
//       title: "Nordiska Rör & VVS",
//       url: "https://nordiska-vvs.jlundholm.com",
//       descriptionHtml: `
//         <p>Demo site for a plumbing business in Gästrikland, Sweden</p>
//         <p>The website is fully responsive, lightning fast and scores for performance (99%) on GTMetrix</p>
//         <p>It leverages multiple challenging CSS components such as text over full-width images and an animated header on mobile that
//         pushes content out of the way to make room for itself</p>
//       `,
//       imageAlign: "left",
//       skills: [],
//       softwareSkills: [],
//     },
//     {
//       id: 1,
//       title: "Develop web applications",
//       descriptionHtml: `
//         <p>You have an idea for your next app but want someone to help you build it.</p>
//         <p>With 5+ years of experience in frontend, backend, database and cloud infrastructure I'm a one-stop shop for taking your idea from idea to launch</p>
//       `,
//       imageAlign: "right",
//       skills: [],
//       softwareSkills: [],
//     },
//   ],
// };

const projects = {
  data: [
    {
      title: "East West Gallery",
      description: "Landing page for a fictional art gallery.",
      url: "https://art-gallery-demo.jlundholm.com",
      displayUrl: "art-gallery-demo.jlundholm.com",
      descriptionHtml: `
        <div>
          <h1>Title</h1>
          <p>Some text here</p>
        </div>
      `,
      imageAlign: "left",
      skills: ["⚡ Building responsive frontend websites using React"],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
      ],
    },
    // {
    //   title: "Cloud Infra-Architecture",
    //   imageAlign: "right",
    //   fileName: "CloudInfraImg",
    //   skills: [
    //     "⚡ Hosting and maintaing your web applications",
    //     "⚡ Manage and develop cloud solutions using Terraform",
    //     "⚡ Automate build and deployment workflows using Github Actions",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "AWS",
    //       fontAwesomeClassname: "simple-icons:amazonaws",
    //       style: {
    //         color: "#FF9900",
    //       },
    //     },
    //     {
    //       skillName: "Terraform",
    //       fontAwesomeClassname: "simple-icons:terraform",
    //       style: {
    //         color: "#7B42BC",
    //       },
    //     },
    //     {
    //       skillName: "MySQL",
    //       fontAwesomeClassname: "simple-icons:mysql",
    //       style: {
    //         color: "#336791",
    //       },
    //     },
    //     {
    //       skillName: "Docker",
    //       fontAwesomeClassname: "simple-icons:docker",
    //       style: {
    //         color: "#1488C6",
    //       },
    //     },
    //     {
    //       skillName: "Github Actions",
    //       fontAwesomeClassname: "simple-icons:github",
    //       style: {
    //         color: "#ffffff",
    //       },
    //     },
    //   ],
    // },
  ],
};

const skills = {
  data: [
    {
      title: "Build a Landing Page for your business",
      imageAlign: "left",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive and performant websites using React",
        "⚡ Develop APIs and integrate with 3rd party APIs",
        "⚡ Creating application backend in Node and Java using Spring Boot",
      ],
      softwareSkills: [],
    },
    {
      title: "Full Stack Development",
      imageAlign: "right",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive and performant websites using React",
        "⚡ Develop APIs and integrate with 3rd party APIs",
        "⚡ Creating application backend in Node and Java using Spring Boot",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        // {
        //   skillName: "Sass",
        //   fontAwesomeClassname: "simple-icons:sass",
        //   style: {
        //     color: "#CC6699",
        //   },
        // },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#FF0000",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Spring Boot",
          fontAwesomeClassname: "simple-icons:spring",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        // {
        //   skillName: "Gatsby",
        //   fontAwesomeClassname: "simple-icons:gatsby",
        //   style: {
        //     color: "#663399",
        //   },
        // },
        // {
        //   skillName: "Flutter",
        //   fontAwesomeClassname: "simple-icons:flutter",
        //   style: {
        //     color: "#02569B",
        //   },
        // },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      imageAlign: "right",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Hosting and maintaing your web applications",
        "⚡ Manage and develop cloud solutions using Terraform",
        "⚡ Automate build and deployment workflows using Github Actions",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Terraform",
          fontAwesomeClassname: "simple-icons:terraform",
          style: {
            color: "#7B42BC",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Github Actions",
          fontAwesomeClassname: "simple-icons:github",
          style: {
            color: "#ffffff",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [],
};

const certifications = {
  certifications: [],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Associate AI Engineer",
          company: "Legato Health Technology",
          company_url: "https://legatohealthtech.com/",
          logo_path: "legato_logo.png",
          duration: "June 2020 - Aug 2021",
          location: "Hyderabad, Telangana",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Android and ML Developer",
          company: "Muffito Incorporation",
          company_url: "https://www.linkedin.com/company/muffito-inc/about/",
          logo_path: "muffito_logo.png",
          duration: "May 2018 - Oct 2018",
          location: "Pune, Maharashtra",
          description:
            "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#9b1578",
        },
        {
          title: "Android Developer",
          company: "FreeCopy Pvt. Ltd.",
          company_url: "https://www.linkedin.com/company/freecopy/about/",
          logo_path: "freecopy_logo.png",
          duration: "Nov 2017 - Dec 2017",
          location: "Ahmedabad, Gujarat",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create web apps and deploy them automatically to multiple environments",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {};

const publications = {};

const contactInfo = {
  email_address: "jonas@jlundholm.com",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    email: "Phone: 🇸🇪(+46)760372563",
    phone: "Email: jonas@jlundholm.com",
  },
  blogSection: {},
  addressSection: {},
  emailSection: {
    subtitle: "Email: jonas@jlundholm.com",
  },
  phoneSection: {
    subtitle: "Phone: 🇸🇪(+46)760372563",
  },
};

export {
  app,
  aboutUs,
  contactInfo,
  copy,
  settings,
  seo,
  greeting,
  aboutMe,
  socialMediaLinks,
  skills,
  projects,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
  offerings,
  work,
};
