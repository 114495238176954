import React from "react";
import "./Hero.css";
import { Fade } from "react-reveal";
import RateCalculator from "../../components/rateCalculator/RateCalculator.js"

export default function Hero(props) {
  return (
      <div className="hero-main">
        <div style={{ paddingTop: '5rem', paddingBottom: '10rem', paddingRight: '1rem', maxWidth: '66%', margin: '0 auto' }}className="greeting-main">
          <div style={{ display: 'flex', justifyContent: 'center'}}>
            <div style={{ flex: 1, color: '#000', lineHeight: 1}}>
              <span style={{fontSize: '4rem'}} >Kick-start your holiday shipping with Shippy</span>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem'}}>
                <div>
                <span style={{ fontSize: '1.4rem', lineHeight: 1.4, color: '#6e6e6e' }}>
                  Get discounted shipping rates with <span style={{color: '#4d148c', fontWeight: 'bold'}}>Fed</span><span style={{color: '#ff6600', fontWeight: 'bold'}}>Ex</span>, <span style={{color: '#2a52be', fontWeight: 'bold'}}>USPS</span> <br/>and <span style={{color: '#351c15', fontWeight: 'bold'}}>UPS
                  </span>
                  <span>, track orders and print shipping labels</span>
                </span>
                </div>
                <div style={{ display: 'flex', marginTop: '3rem' }}>
                  {/* <div style={{ backgroundColor: '#6738ee', padding: '1rem 2rem 1rem 2rem', borderRadius: '0.2rem' }}>
                    <span style={{ fontFamily: 'DM Sans Regular', color: '#FFF', fontSize: '1.3rem', fontWeight: 300, lineHeight: '32px', }}>Get started for free</span>
                  </div> */}
                </div>
              </div>
            </div>

            <div style={{ flex: 1, color: '#000'}}>
              <RateCalculator/>
            </div>
          </div>
          <Fade top duration={500}>
          </Fade>
        </div>
    </div>
  );
}
