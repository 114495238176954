import React from "react";
import "./Features.css";

export default function Features(props) {
  return (
      <div style={{ backgroundColor: '#6738ee',  paddingTop: '5rem', paddingBottom: '5rem' }}className="features-main">
        <div style={{ maxWidth: '80%', margin: '0 auto' }}className="greeting-main">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div style={{ flex: 1, color: '#FFF', textAlign: 'center', fontSize: '4.2rem', lineHeight: 1}}>
              Everything you need to start shipping
            </div>
            <div style={{ display: 'flex', paddingTop: '4rem', justifyContent: 'center'}}>
              <div style={{ width: '38%', height: '10rem', padding: '3rem', paddingLeft: '3rem', paddingRight: '3rem', lineHeight: '1.5', borderRadius: '0.5rem', backgroundColor: '#7044ef', margin: '0 auto' }}>
                Compare shipping rates between carriers like FedEx, DHL, UPS and USPS
              </div>
              <div style={{ width: '38%', height: '10rem', padding: '3.2rem', paddingLeft: '3rem', paddingRight: '3rem', borderRadius: '0.5rem', backgroundColor: '#7044ef', margin: '0 auto' }}>
                Get access to discounted shipping rates up to 70%
              </div>
            </div>
            <div style={{ display: 'flex', paddingTop: '4rem', justifyContent: 'center'}}>
              <div style={{ width: '38%', height: '10rem', paddingTop: '4rem', paddingLeft: '3rem', paddingRight: '3rem', borderRadius: '0.5rem', backgroundColor: '#7044ef', margin: '0 auto' }}>
                Download and print shipping labels
              </div>
              <div style={{ width: '38%', height: '10rem', padding: '4rem', paddingLeft: '3rem', paddingRight: '3rem', borderRadius: '0.5rem', backgroundColor: '#7044ef', margin: '0 auto' }}>
                Track orders and shipments in one platform
              </div>
            </div>
            <div>
              
              <div>

              </div>
            </div>
          </div>
        </div>
    </div>
  );
}
