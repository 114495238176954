import React, { useState } from "react";
import "./RateCalculator.css";
import CountrySelect from "../countrySelect/CountrySelect";
import Spinner from "../spinner/Spinner.js";

export default function RateCalculator(props) {
	const [isLoadingRates, setIsLoadingRates] = useState(false);
	const [shippingRatesWithAttributes, setShippingRatesWithAttributes] = useState(null);

	const DEFAULT_COUNTRY = 'United States';

	const [fromCountry, setFromCountry] = useState(DEFAULT_COUNTRY);
	const [fromPostalCode, setFromPostalCode] = useState('');
	const [fromCity, setFromCity] = useState('');
	const [fromState, setFromState] = useState('');
	const [toCountry, setToCountry] = useState(DEFAULT_COUNTRY);
	const [toPostalCode, setToPostalCode] = useState('');
	const [toCity, setToCity] = useState('');
  const [toState, setToState] = useState('');
  
	const handleRequestRates = () => {
    if (isLoadingRates) {
      return null;
    }
    
		if ((fromCountry === 'United States' || fromCountry === 'Canada') && !fromState) {
			return null
		}
		if ((toCountry === 'United States' || toCountry === 'Canada') && !toState) {
			return null
		}
		
		if (!fromCity || !fromPostalCode || !fromCountry) {
			return null;
		}

		if (!toCity || !toPostalCode || !toCountry) {
			return null;
		}

		const requestBody = JSON.stringify({
			"address_from": {
					"city": fromCity,
					"zip": fromPostalCode,
					"state": fromState,
					"country": fromCountry,
			},
			"address_to": {
					"city": toCity,
					"zip": toPostalCode,
					"state": toState,
					"country": toCountry,
			},
			"parcels": [{
					"length": "5",
					"width": "5",
					"height": "5",
					"distance_unit": "in",
					"weight": "2",
					"mass_unit": "lb"
			}],
			"async": false
		})
	
    const requestOptions = {
      method: 'POST',
      headers: { 
        "Content-Type": "application/json",
      },
      body: requestBody
		}

    setIsLoadingRates(true);
    // fetch('https://api.goshippo.com/shipments', requestOptions)
    fetch('http://localhost:5000/api/public/request-rates', requestOptions)
      .then(response => response.json())
      .then(res => {
        const shippingRatesWithAttributes = res.data.rates.filter((rate) => rate.attributes.length > 0);
        
        setShippingRatesWithAttributes(shippingRatesWithAttributes);
        setIsLoadingRates(false);
      })
      .catch(error =>{
        setIsLoadingRates(false);
      });
  };

	const handleFromCountryChange = (e) => setFromCountry(e.target.value);
  const handleFromPostalCodeChange = (e) => setFromPostalCode(e.target.value);
  const handleFromCityChange = (e) => setFromCity(e.target.value);
  const handleFromStateChange = (e) => setFromState(e.target.value);

  const handleToCountryChange = (e) => setToCountry(e.target.value);
  const handleToPostalCodeChange = (e) => setToPostalCode(e.target.value);
  const handleToCityChange = (e) => setToCity(e.target.value);
  const handleToStateChange = (e) => setToState(e.target.value);

	return (
      <div style={{ backgroundColor: '#f5f5f5', borderRadius: '0.1rem' }}>
        <div style={{ paddingTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem', paddingBottom: '2rem', display: 'flex', margin: '0 auto' }} className="greeting-main">
				<div style={{ display: 'flex', flexDirection:'column', paddingRight: '1rem'}}>
					<span style={{ fontSize: '1.2rem' }}>Shipping from</span>
					<CountrySelect 
						value={fromCountry} 
						onChange={handleFromCountryChange} 
					/>
					<input type="text" className="rate-calculator-input" value={fromPostalCode} onChange={handleFromPostalCodeChange} placeholder="Postal code" />
					<input type="text" className="rate-calculator-input" value={fromCity} onChange={handleFromCityChange} placeholder="City" />
					<input type="text" className="rate-calculator-input" value={fromState} onChange={handleFromStateChange} placeholder="State" />
				</div>
				<div style={{ paddingRight: '1rem'}}>
					<span style={{ fontSize: '1.2rem' }}>Shipping to</span>
					<CountrySelect 
						value={toCountry} 
						onChange={handleToCountryChange} 
					/>
					<input type="text" className="rate-calculator-input" value={toPostalCode} onChange={handleToPostalCodeChange} placeholder="Postal code" />
					<input type="text" className="rate-calculator-input" value={toCity} onChange={handleToCityChange} placeholder="City" />
					<input type="text" className="rate-calculator-input" value={toState} onChange={handleToStateChange} placeholder="State" />
				</div>

        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '3rem', paddingBottom: '1rem'}}>
            <div style={{ display: 'flex', padding: '1rem', width: '7rem', justifyContent: 'center', backgroundColor: '#6738ee', color: '#FFF', cursor: 'pointer' }} onClick={handleRequestRates}>
                {isLoadingRates && <Spinner spinnerColor='#FFF'/>}
                {!isLoadingRates && 
                    <span>
                        See rates
                    </span>
                }
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingRight: '3rem', paddingBottom: '1rem'}}>
          {!isLoadingRates && shippingRatesWithAttributes && shippingRatesWithAttributes.length === 0 && 
						<span style={{ paddingLeft: '2rem' }}>No rates found between these cities</span>
					} 
					{shippingRatesWithAttributes && shippingRatesWithAttributes.map((shippingRateWithAttribute) => {
            const attributes = shippingRateWithAttribute.attributes;
            return (
                attributes && attributes.map((attribute) => {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '2rem', marginTop: '1rem', marginBottom: '1rem'}}key={shippingRateWithAttribute.object_id}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div style={{ display: 'flex', alignItems: 'flex-end'}}>
                                    <img alt="carrier_image" style={{ width: '60px', objectFit: 'contain' }} src={shippingRateWithAttribute.provider_image_200}></img>
                                    <span style={{ fontSize: '1.1rem', marginLeft: '1rem'}}>{shippingRateWithAttribute.servicelevel.name}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    {attribute === 'BESTVALUE' && 
                                        <>
                                            <span style={{ fontSize: '1.2rem', textAlign: 'end'}}>Best value</span>
                                            <span style={{ fontSize: '1.1rem', marginLeft: '1rem', fontFamily: 'Inter'}}>{shippingRateWithAttribute.currency} {shippingRateWithAttribute.amount}</span>
                                        </>
                                    }
                                    {attribute === 'CHEAPEST' && 
                                    <>
                                        <span style={{ marginBottom: '0.3rem', fontSize: '1.2rem', textAlign: 'end'}}>Cheapest</span>
                                        <span style={{ fontSize: '1.1rem', marginLeft: '1rem', fontFamily: 'Inter'}}>{shippingRateWithAttribute.currency} {shippingRateWithAttribute.amount}</span>
                                        </>
                                    }
                                    {attribute === 'FASTEST' && 
                                        <>
                                            <span style={{ marginBottom: '0.3rem', fontSize: '1.2rem', textAlign: 'end'}}>Fastest</span>
                                            <span style={{ fontSize: '1.1rem', marginLeft: '1rem', fontFamily: 'Inter'}}>{shippingRateWithAttribute.currency} {shippingRateWithAttribute.amount}</span>
                                        </>
                                    }
                                </div>
                            </div>
                            <div style={{marginTop: '0.2rem' }}>
                                <span style={{ fontFamily: 'Inter', fontSize: '1.1rem' }}>{shippingRateWithAttribute.duration_terms}</span>      
                            </div>
														<div style={{marginTop: '1rem' }}>
                                {shippingRateWithAttribute.estimated_days === 1 && <span style={{ fontFamily: 'Inter', fontSize: '1.1rem' }}>{`Est. delivery in ${shippingRateWithAttribute.estimated_days} day`}</span> }
                                {shippingRateWithAttribute.estimated_days > 1 && <span style={{ fontFamily: 'Inter', fontSize: '1.1rem' }}>{`Est. delivery in ${shippingRateWithAttribute.estimated_days} days`}</span> }    
                            </div>
                        </div>
                    )
                }
            ) 
            )
          })}
        </div>
    </div>
  );
}
