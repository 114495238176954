import React from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
import { settings, copy } from "../../portfolio.js";
import SeoHeader from "../seoHeader/SeoHeader";

function Header({ theme, seoTitle, seoDescription }) {
  const link = settings.isSplash ? "/splash" : "/";

  return (
    <Fade top duration={1000} distance="20px">
      <SeoHeader seoTitle={seoTitle} seoDescription={seoDescription} />
      <div>
        <header className="header">
          <NavLink to={link} tag={Link} className="logo">
            <span className="logo-name" style={{ color: theme.logo }}>
              
            </span>
          </NavLink>
          {/* <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label> */}
          <ul
            className="menu"
            style={{ backgroundColor: theme.primaryBackgroundColor }}
          >
            {/* <li>
              <NavLink
                to={`/`}
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text, fontSize: "1.5rem" }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Tidy
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                to={`${copy.get("privacyPolicyLinkUrl")}`}
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text, fontSize: "1.5rem" }}
                onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                {copy.get("privacyPolicyLinkText")}
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to={`${copy.get("pricingLinkUrl")}`}
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                className="header-link"
                style={{ marginRight: '2rem', fontFamily: 'Inter', fontSize: "1.1rem" }}
                // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                // onMouseOut={(event) => onMouseOut(event)}
              >
                {copy.get("pricingLinkText")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${copy.get("signInLinkUrl")}`}
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                className="header-link"
                style={{ fontFamily: 'Inter', fontSize: "1.1rem" }}
                // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                // onMouseOut={(event) => onMouseOut(event)}
              >
                {copy.get("signInLinkText")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${copy.get("signUpLinkUrl")}`}
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                className="header-link"
                style={{ fontFamily: 'Inter', fontSize: "1.1rem" }}
                // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                // onMouseOut={(event) => onMouseOut(event)}
              >
                {copy.get("signUpLinkText")}
              </NavLink>
            </li>
          </ul>
        </header>
      </div>
    </Fade>
  );
}

export default Header;
